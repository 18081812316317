import React, { useEffect, useState } from "react";
import axios from "axios";
import Papa from "papaparse";
import { Table, Button } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

const UserTable = () => {
  const [data, setData] = useState(null);

  const origin = process.env.REACT_APP_BACKEND_ORIGIN;
  axios.defaults.withCredentials = true;

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    try {
      const response = await axios.get(`${origin}/api/v1/users/getAllUsers`);
      setData(response.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const downloadCSV = (jsonData) => {
    const flattenedData = flattenData(jsonData);
    const csv = Papa.unparse(flattenedData);
    const fileName = getFileName();
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", fileName);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const flattenData = (users) => {
    const flattenedData = [];
    users.forEach((user) => {
      user.search.forEach((searchGroup) => {
        searchGroup.forEach((search) => {
          search.clickedItems.forEach((item , i) => {
            flattenedData.push({
              name: user.name,
              email: user.email,
              keyword: search.keyword,
              searchTime: formatToEasternTime(search.searchTime[i]),
              itemTitle: item.title,
              itemPrice: item.price,
              itemLink: item.link,
              itemTime: formatToEasternTime(item.time),
              itemWebsite: item.website,
            });
          });
        });
      });
    });
    return flattenedData;
  };

  const getFileName = () => {
    const now = new Date();
    const formattedDate = `${now.getUTCDate()}-${
      now.getUTCMonth() + 1
    }-${now.getUTCFullYear()}`;
    const formattedTime = `${now.getUTCHours()}-${now.getUTCMinutes()}`;
    return `data_${formattedDate}_${formattedTime}_GMT.csv`;
  };

  // const formatToEasternTime = (dateString) => {
  //   try {
  //     // Split the date string to extract date and time components
  //     const [datePart, timePart] = dateString.split(' ');
  //     const [day, month, year] = datePart.split('/');
  //     const [hours, minutes] = timePart.split(':');

  //     // Create a Date object using the extracted values in UTC
  //     const date = new Date(Date.UTC(year, month - 1, day, hours, minutes));

  //     if (isNaN(date)) {
  //       throw new Error('Invalid Date');
  //     }

  //     // Format the date to Eastern Time
  //     const options = {
  //       timeZone: 'America/New_York',
  //       month: '2-digit',
  //       day: '2-digit',
  //       year: 'numeric',
  //       hour: '2-digit',
  //       minute: '2-digit',
  //     };

  //     return new Intl.DateTimeFormat('en-US', options).format(date);
  //   } catch (error) {
  //     console.error('Error formatting date:', error);
  //     return 'Invalid Date';
  //   }
  // };

  const formatToEasternTime = (isoString) => {
    try {
      // Create a Date object from the ISO string
      const date = new Date(isoString);

      if (isNaN(date.getTime())) {
        throw new Error("Invalid Date");
      }

      // Format the date to Eastern Time
      const options = {
        timeZone: "America/New_York",
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      };

      return new Intl.DateTimeFormat("en-US", options).format(date);
    } catch (error) {
      console.error("Error formatting date:", error);
      return "Invalid Date";
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
  ];

  const searchColumns = [
    {
      title: " Search Keyword",
      dataIndex: "keyword",
      key: "keyword",
    },
    {
      title: "Search Time (US EST)",
      dataIndex: "searchTime",
      key: "searchTime",
    },
    {
      title: "Website",
      dataIndex: "website",
      key: "website",
    },
    {
      title: "Product Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Click Time (US EST)",
      dataIndex: "time",
      key: "time",
    },
    {
      title: "Product Link",
      dataIndex: "link",
      key: "link",
      render: (text) => (
        <a href={text} target="_blank" rel="noopener noreferrer" className="text-blue-600">
          Product Link
        </a>
      ),
    },
  ];

  // const expandedRowRender = (user) => (
  //   <Table
  //     columns={searchColumns}
  //     dataSource={user.search.flatMap(searchItems =>
  //       searchItems.flatMap(searchDetail =>
  //         searchDetail.clickedItems.map(item => ({
  //           keyword: searchDetail.keyword,
  //           searchTime: formatToEasternTime(searchDetail.searchTime),
  //           website: item.website,
  //           title: item.title,
  //           price: item.price,
  //           time: formatToEasternTime(item.time),
  //           link: item.link,
  //         }))
  //       )
  //     )}
  //     pagination={false}
  //     rowKey="link"
  //   />
  // );

  const expandedRowRender = (user) => (
    <Table
      columns={searchColumns}
      dataSource={user.search.flatMap((searchItems) =>
        searchItems.flatMap((searchDetail) =>
          searchDetail.clickedItems.map((item, i) => {
            console.log(searchDetail.searchTime);
            return {
              keyword: searchDetail.keyword,
              searchTime: formatToEasternTime(searchDetail.searchTime[i]), // Ensure searchTime is valid
              website: item.website,
              title: item.title,
              price: item.price,
              time: formatToEasternTime(item.time),
              link: item.link,
            };
          })
        )
      )}
      pagination={false}
      rowKey="link"
    />
  );

  if (!data || !data.users || data.users.length === 0) {
    return <h1>Loading...</h1>;
  }

  return (
    <div className="container mx-auto px-4 min-h-full pb-20">
      <div className="flex">
        <h1 className="text-2xl font-bold my-4">User Data</h1>
        <Button
          type="primary"
          onClick={() => downloadCSV(data.users)}
          style={{ marginLeft: "auto" }}
        >
          Download CSV
        </Button>
      </div>

      <Table
        columns={columns}
        dataSource={data.users}
        expandable={{
          expandedRowRender,
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <UpOutlined onClick={(e) => onExpand(record, e)} />
            ) : (
              <DownOutlined onClick={(e) => onExpand(record, e)} />
            ),
        }}
        rowKey="email"
      />
    </div>
  );
};

export default UserTable;
