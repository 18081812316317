import {createSlice } from '@reduxjs/toolkit'

const userSlice = createSlice({
    name : 'user',
    initialState:{
        name: "AT",
        email: "BT",
       role : 'user'
    },
    reducers :{
        setRole : (state , action)=>{
            state.role = action.payload;
            console.log(action.payload)
        },
        setName : (state , action)=>{
            state.name = action.payload;
            console.log(action.payload)
        },
        setEmail : (state , action)=>{
            state.email = action.payload;
            console.log(action.payload)
        }

    }
})

export const {setRole , setName , setEmail} = userSlice.actions;
export default userSlice.reducer; 