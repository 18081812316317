import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

const ResetPassword = () => {
  const origin = process.env.REACT_APP_BACKEND_ORIGIN;
  axios.defaults.withCredentials = true;

  const navigateTo = useNavigate();
  const location = useLocation();

  const [inputs, setInputs] = useState({
    email: "",
    password: "",
    passwordConfirm: ""
  });

  const [showPassword, setShowPassword] = useState({
    password: false,
    passwordConfirm: false
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const email = params.get('email');
    if (email) {
      setInputs({ ...inputs, email: email });
    }
  }, [location]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: value });

    // Validate and clear errors for the specific field being updated
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };

      if (name === "email") {
        if (!value) {
          newErrors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(value)) {
          newErrors.email = "Email is invalid";
        } else {
          delete newErrors.email;
        }
      } else if (name === "password") {
        if (!value) {
          newErrors.password = "Password is required";
        } else if (value.length < 8) {
          newErrors.password = "Password must be at least 8 characters long";
        } else {
          delete newErrors.password;
        }
      } else if (name === "passwordConfirm") {
        if (!value) {
          newErrors.passwordConfirm = "Confirm Password is required";
        } else if (value !== inputs.password) {
          newErrors.passwordConfirm = "Passwords do not match";
        } else {
          delete newErrors.passwordConfirm;
        }
      }

      return newErrors;
    });
  };

  const validate = () => {
    const errors = {};
    if (!inputs.email) errors.email = "Email is required";
    else if (!/\S+@\S+\.\S+/.test(inputs.email)) errors.email = "Email is invalid";

    if (!inputs.password) errors.password = "Password is required";
    else if (inputs.password.length < 8) errors.password = "Password must be at least 8 characters long";

    if (!inputs.passwordConfirm) errors.passwordConfirm = "Confirm Password is required";
    else if (inputs.password !== inputs.passwordConfirm) errors.passwordConfirm = "Passwords do not match";

    return errors;
  }

  const handleResetPassword = async () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      const response = await axios.post(`${origin}/api/v1/users/resetPassword`, inputs);
      toast.success("Password reset successful!");
      setInputs({
        email: "",
        password: "",
        passwordConfirm: ""
      });
      navigateTo("/");
      console.log(response.data);
    } catch (err) {
      toast.error(err.response?.data?.message || "Error resetting password");
      console.log(err);
    }
  };

  const togglePasswordVisibility = (field) => {
    setShowPassword({ ...showPassword, [field]: !showPassword[field] });
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleResetPassword();
    }
  }

  return (
    <>
      <section className="text-gray-600 body-font -mt-12">
        <div className="container px-5 py-24 mx-auto flex flex-wrap items-center">
          <div className="w-4/5 md:w-1/2 mx-auto justify-center bg-gray-100 rounded-lg p-8 flex flex-col md:ml-auto mt-10 md:mt-0">
            <h2 className="text-gray-900 text-lg font-medium title-font mb-5">
              Reset Password
            </h2>
            <div className="relative mb-4">
              <label htmlFor="email" className="leading-7 text-sm text-gray-600">
                Email
              </label>
              <input
                type="email"
                name="email"
                onChange={handleChange}
                value={inputs.email}
                onKeyDown={handleKeyDown}
                className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
              {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email}</p>}
            </div>
            <div className="relative mb-4">
              <label htmlFor="password" className="leading-7 text-sm text-gray-600">
                Password
              </label>
              <div className="relative w-full">
                <input
                  type={showPassword.password ? "text" : "password"}
                  name="password"
                  onChange={handleChange}
                  value={inputs.password}
                  onKeyDown={handleKeyDown}
                  className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8"
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 px-3 flex items-center text-gray-600"
                  onClick={() => togglePasswordVisibility('password')}
                >
                  {showPassword.password ? <EyeInvisibleOutlined /> : <EyeTwoTone />}
                </button>
              </div>
              {errors.password && <p className="text-red-500 text-xs mt-1">{errors.password}</p>}
            </div>
            <div className="relative mb-4">
              <label htmlFor="passwordConfirm" className="leading-7 text-sm text-gray-600">
                Confirm Password
              </label>
              <div className="relative w-full">
                <input
                  type={showPassword.passwordConfirm ? "text" : "password"}
                  name="passwordConfirm"
                  onChange={handleChange}
                  value={inputs.passwordConfirm}
                  onKeyDown={handleKeyDown}
                  className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8"
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 px-3 flex items-center text-gray-600"
                  onClick={() => togglePasswordVisibility('passwordConfirm')}
                >
                  {showPassword.passwordConfirm ? <EyeInvisibleOutlined /> : <EyeTwoTone />}
                </button>
              </div>
              {errors.passwordConfirm && <p className="text-red-500 text-xs mt-1">{errors.passwordConfirm}</p>}
            </div>
              <button
                onClick={handleResetPassword}
                className="text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg"
              >
                Reset Password
              </button>
            
          </div>
        </div>
      </section>
      <ToastContainer />
    </>
  )
}

export default ResetPassword;
