import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";

const ResultCard = ({ info }) => {
  const origin = process.env.REACT_APP_BACKEND_ORIGIN;
  axios.defaults.withCredentials = true;

  const query = useSelector((store) => store.searchQuery.query.searchQuery);

  console.dir(info);

  const handleClick = async (info) => {
    try {
      const { external_link : link, title, price } = info;

      const parsedUrl = new URL(link);
      const domain = parsedUrl.hostname;

      const response = await axios.post(`${origin}/api/v1/click`, {
        link,
        domain,
        price,
        query,
        title,
      });

      console.log("Data sent successfully!", response.data);
      // Handle success
    } catch (error) {
      console.error("Error sending data:", error);
      // Handle error
    }
  };

  return (
    <Link
      to={info.external_link}
      className="w-3/4 lg:w-1/4 sm:w-1/3 h-96 md:h-80 p-2 m-2 rounded-md bg-gray-100"
      // target="_blank"
      onClick={() => handleClick(info)}
    >
      <img
        className="h-2/3 m-auto z-0"
        src={info.thumbnails[0]}
        style={{
          mixBlendMode: 'multiply',  // CSS property to blend the image
          backgroundColor: 'white',  // Background color to blend with
          
        }}
        alt="Product"
      ></img>

      <div className=" h-1/3  bg-slate-200 p-1 rounded-lg">
        <div className="bg-slate-200 m">
          <h1>{info.title.substring(0, 30) + "..."}</h1>
          {info.price ? <h1>Price : {info.price}</h1> : <></>}
          {info.rating ? <h1>Rating : {info.rating}</h1> : <></>}
          {info.seller ? <h1>seller : {info.seller}</h1> : <></>}
        </div>
      </div>
    </Link>
  );
};

export default ResultCard;
