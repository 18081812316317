// import React, { useState } from 'react';
// import { Bar } from 'react-chartjs-2';
// import { Chart as ChartJS, BarElement, CategoryScale, LinearScale } from 'chart.js';

// ChartJS.register(BarElement, CategoryScale, LinearScale);

// const Wallet = () => {
//   const [filter, setFilter] = useState('Week');

//   const dummyData = {
//     Week: {
//       labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
//       data: [120, 200, 150, 80, 90, 250, 300],
//     },
//     Month: {
//       labels: Array.from({ length: 30 }, (_, i) => `Day ${i + 1}`),
//       data: Array.from({ length: 30 }, () => Math.floor(Math.random() * 300)),
//     },
//     Year: {
//       labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
//       data: [1200, 1900, 2100, 2500, 2300, 1800, 2200, 2700, 2000, 2400, 2800, 2600],
//     }
//   };

//   const chartData = {
//     labels: dummyData[filter].labels,
//     datasets: [
//       {
//         label: 'Coins Earned',
//         data: dummyData[filter].data,
//         backgroundColor: '#10B981',
//       },
//     ],
//   };

//   return (
//     <div className="max-w-md mx-auto p-4 mt-14  bg-white shadow-lg rounded-lg">
//       <div className="flex justify-between mb-4">
//         <button onClick={() => setFilter('Week')} className={`px-4 py-2 ${filter === 'Week' ? 'bg-teal-500 text-white' : 'bg-gray-200'}`}>
//           Week
//         </button>
//         <button onClick={() => setFilter('Month')} className={`px-4 py-2 ${filter === 'Month' ? 'bg-teal-500 text-white' : 'bg-gray-200'}`}>
//           Month
//         </button>
//         <button onClick={() => setFilter('Year')} className={`px-4 py-2 ${filter === 'Year' ? 'bg-teal-500 text-white' : 'bg-gray-200'}`}>
//           Year
//         </button>
//       </div>
//       <div className="text-center mb-4">
//         <h2 className="text-xl font-bold">{filter} Overview</h2>
//         <p className="text-gray-600">{`Showing coins earned each ${filter === 'Week' ? 'day of the week' : filter === 'Month' ? 'day of the month' : 'month of the year'}`}</p>
//       </div>
//       <Bar data={chartData} options={{ responsive: true }} />
//     </div>
//   );
// };

// export default Wallet;
import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale } from 'chart.js';

ChartJS.register(BarElement, CategoryScale, LinearScale);

const Wallet = () => {
  const [filter, setFilter] = useState('Week');

  const dummyData = {
    Week: {
      labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      data: [120, 200, 150, 80, 90, 250, 300],
    },
    Month: {
      labels: Array.from({ length: 30 }, (_, i) => `Day ${i + 1}`),
      data: Array.from({ length: 30 }, () => Math.floor(Math.random() * 300)),
    },
    Year: {
      labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      data: [1200, 1900, 2100, 2500, 2300, 1800, 2200, 2700, 2000, 2400, 2800, 2600],
    }
  };

  const chartData = {
    labels: dummyData[filter].labels,
    datasets: [
      {
        label: 'Coins Earned',
        data: dummyData[filter].data,
        backgroundColor: '#10B981',
        borderRadius: 10,  // Make bars rounded
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,  // Hide the legend if you don't want to show it
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: true,
        },
      },
    },
  };

  return (
    <div className="max-w-2xl mx-auto p-4 mt-14 bg-white shadow-lg rounded-lg">
      <div className="flex justify-between mb-4">
        <button onClick={() => setFilter('Week')} className={`px-4 py-2 ${filter === 'Week' ? 'bg-teal-500 text-white' : 'bg-gray-200'}`}>
          Week
        </button>
        <button onClick={() => setFilter('Month')} className={`px-4 py-2 ${filter === 'Month' ? 'bg-teal-500 text-white' : 'bg-gray-200'}`}>
          Month
        </button>
        <button onClick={() => setFilter('Year')} className={`px-4 py-2 ${filter === 'Year' ? 'bg-teal-500 text-white' : 'bg-gray-200'}`}>
          Year
        </button>
      </div>
      <div className="text-center mb-4">
        <h2 className="text-xl font-bold">{filter} Overview</h2>
        <p className="text-gray-600">{`Showing coins earned each ${filter === 'Week' ? 'day of the week' : filter === 'Month' ? 'day of the month' : 'month of the year'}`}</p>
      </div>
      <Bar data={chartData} options={chartOptions} />
    </div>
  );
};

export default Wallet;
