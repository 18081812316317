import { createSlice } from "@reduxjs/toolkit";

const logInStatusSlice = createSlice({
    name : "login status",
    initialState : {
        isLoggedIn : false
    },
    reducers : {
        setLoginStatus : (state , action)=>{
            console.log(action.payload);
            state.isLoggedIn = action.payload;
        }
    }
})

export const {setLoginStatus} = logInStatusSlice.actions;
export default logInStatusSlice.reducer;
