import React, { useState } from 'react'
import ResultCard from './ResultCard'
import { useSelector} from 'react-redux'
import { Link } from 'react-router-dom';



const ResultsContainer = () => {

    const data = useSelector(store=>store?.response?.response?.data?.response?.shopping_results);
    console.log(data);

  return (
    <div className='flex flex-wrap w-full justify-center mt-20 '>
      {data?.map(( d,i) =>(
        <ResultCard key = {i} info = {d}/>  
    ))}  
    </div>
  )
}

export default ResultsContainer


