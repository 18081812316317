// import React from 'react';
// import { useNavigate } from 'react-router-dom';
// import { HomeOutlined, WalletOutlined, UserOutlined } from '@ant-design/icons';

// const BottomNavbar = () => {
//   const navigate = useNavigate();

//   return (
//     <div className="fixed bottom-0 left-0 w-full bg-white shadow-md border-t border-gray-200">
//       <div className="flex justify-around items-center py-2">
//         <div
//           className="flex flex-col items-center cursor-pointer"
//           onClick={() => navigate('/')}
//         >
//           <HomeOutlined className="text-2xl text-gray-600" />
//           <span className="text-sm text-gray-600">Shop</span>
//         </div>
//         <div
//           className="flex flex-col items-center cursor-pointer"
//           onClick={() => navigate('/wallet')}
//         >
//           <WalletOutlined className="text-2xl text-gray-600" />
//           <span className="text-sm text-gray-600">Wallet</span>
//         </div>
//         <div
//           className="flex flex-col items-center cursor-pointer"
//           onClick={() => navigate('/userProfile')}
//         >
//           <UserOutlined className="text-2xl text-gray-600" />
//           <span className="text-sm text-gray-600">Profile</span>    
//         </div>
//       </div>
//     </div>
//   );
// };

// export default BottomNavbar;


import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { HomeOutlined, WalletOutlined, UserOutlined } from '@ant-design/icons';

const BottomNavbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(location.pathname);

  // Function to handle navigation and set the active tab
  const handleNavigation = (path) => {
    navigate(path);
    setActiveTab(path);
  };

  return (
    <div className="fixed bottom-0 left-0 w-full bg-gray-500  shadow-md border-t border-gray-200">
      <div className="flex justify-around items-center py-2">
        <div
          className={`flex flex-col items-center cursor-pointer ${
            activeTab === '/' ? 'text-white' : 'text-gray-300'
          }`}
          onClick={() => handleNavigation('/')}
        >
          <HomeOutlined className="text-2xl" />
          <span className="text-sm">Shop</span>
        </div>
        <div
          className={`flex flex-col items-center cursor-pointer ${
            activeTab === '/wallet' ? 'text-white' : 'text-gray-300'
          }`}
          onClick={() => handleNavigation('/wallet')}
        >
          <WalletOutlined className="text-2xl" />
          <span className="text-sm">Wallet</span>
        </div>
        <div
          className={`flex flex-col items-center cursor-pointer ${
            activeTab === '/userProfile' ? 'text-white' : 'text-gray-300'
          }`}
          onClick={() => handleNavigation('/userProfile')}
        >
          <UserOutlined className="text-2xl" />
          <span className="text-sm">Profile</span>
        </div>
      </div>
    </div>
  );
};

export default BottomNavbar;
