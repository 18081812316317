// import React from 'react'
// import { ToastContainer } from 'react-toastify';
// import NavBar from './NavBar';
// import { Outlet } from 'react-router-dom';
// import BottomNavbar from './BottomNavbar';


// const Body = () => {
//   return (
//     <>
//         <NavBar/>
//         <ToastContainer/>
//         <Outlet/>
//         <BottomNavbar/>
//     </>
//   )
// }

// export default Body

import React from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import NavBar from './NavBar';
import { Outlet } from 'react-router-dom';
import BottomNavbar from './BottomNavbar';

const Body = () => {
  const isLoggedIn = useSelector((store) => store.logInStatus.isLoggedIn);

  return (
    <>
      <NavBar />
      <ToastContainer />
      <Outlet />
      {isLoggedIn && <BottomNavbar />}
    </>
  );
};

export default Body;
