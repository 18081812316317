import React from 'react';
import { useSelector } from 'react-redux';
import { Avatar, Card } from 'antd';
import 'antd/dist/reset.css'; // Ensure Ant Design styles are included

const UserProfile = () => {
  // Retrieve user details from Redux store
  const { name, email, role } = useSelector((state) => ({
    name: state.user.name,
    email: state.user.email,
    role: state.user.role,
  }));

  return (
    <div className="flex justify-center items-center min-h-[80vh] bg-gray-100 p-4">
      <Card className="w-full max-w-sm mx-auto" bordered={false}>
        <div className="flex flex-col items-center">
          <Avatar
            size={96}
            src="https://via.placeholder.com/150" // Placeholder image, replace with actual avatar if available
            className="mb-4"
          />
          <h2 className="text-xl font-semibold mb-2">{name}</h2>
          <p className="text-gray-700">{email}</p>
          <p className="text-gray-500 mt-2">{role}</p>
        </div>
      </Card>
    </div>
  );
};

export default UserProfile;
