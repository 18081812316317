import React, { useEffect, useState } from 'react'
import ResultCard from './ResultShimmerCard';
import { useSelector, useDispatch } from 'react-redux';
import Shimmer from './Shimmer';
import ResultsContainer from './ResultsContainer';
import axios from 'axios';
import { setResponse } from '../utils/responseSlice';
import { setQuery } from '../utils/searchQuerySlice';
import { Link } from 'react-router-dom';
import BottomNavbar from './BottomNavbar';


const ResultsPage = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [clicked, setClicked] = useState(false);

  const data = useSelector(store => store?.response);
  const query = useSelector(store => store.searchQuery.query.searchQuery)
  const dStatus = useSelector(store => store?.response?.response.status);
  const isLoggedIn = useSelector((store) => store.logInStatus.isLoggedIn);


  const origin = process.env.REACT_APP_BACKEND_ORIGIN 
  axios.defaults.withCredentials = true;

  const dispatch = useDispatch();

  useEffect(() => {
    // Restore state from local storage
    const savedQuery = localStorage.getItem('searchQuery');
    if (savedQuery) {
      setSearchQuery(savedQuery);
      dispatch(setQuery({ searchQuery: savedQuery }));
      handleC(savedQuery);
    } else {
      setSearchQuery(query);
    }
  }, []);

  useEffect(() => {
    // Save state to local storage whenever searchQuery changes
    if (searchQuery) {
      localStorage.removeItem('searchQuery');
      localStorage.setItem('searchQuery', searchQuery);
    }
  }, [searchQuery]);

  const handleClick = async () => {
    try {
      console.log(searchQuery);
      console.log(query);
      const response = await axios.get(`${origin}/api/v1/search?query=${query}`); 
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleC = async (queryParam) => {
    try {
      dispatch(setResponse({}));

      const searchQ = queryParam || searchQuery;
      console.log(searchQ);
      dispatch(setQuery({ searchQuery: searchQ }));

      const response = await axios.get(`${origin}/api/v1/search?query=${searchQ}`);
      dispatch(setResponse(response.data));
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleC();
    }
  };

  return (
    <div className='flex flex-col'>
      <div className='flex flex-row bg-gray-300 w-full z-10 h-20 fixed shadow-lg items-center lg:px-4'>
        <h1 className='text-2xl ml-1 md:text-3xl md:ml-2 lg:text-4xl lg:ml-4 italic text-gray-700 '>Cirnomy</h1>
        <div className='flex p-2 space-x-2 w-2/4 ml-4'>
          <input 
            type="text" 
            id="name" 
            name="name" 
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyPress={handleKeyPress}
            className="w-full bg-white rounded border border-gray-500 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
          />
          <div  
            onClick={() => handleC()}
            className='cursor-pointer text-green-900 w-16 rounded-md p-2 bg-green-300 border-solid border-2 border-green-400 hover:bg-green-500 hover:text-white'>
            Search
          </div> 
        </div>
        <div className='ml-auto flex '>
          <Link to={'/'} className='flex mr-4'>
            <div  
              className='cursor-pointer text-white w-16  p-2 bg-indigo-600 rounded-md border-solid border-2 hover:bg-indigo-800 '>
              Home
            </div> 
          </Link>
          <Link to={"/allUsers"} className='mr-4'>
            <div  
              className='cursor-pointer text-white   p-2 bg-indigo-600 rounded-md border-solid border-2 hover:bg-indigo-800 '>
              Admin Console
            </div> 
          </Link>
        </div>

      </div>
      
      {dStatus === 'success' ? ( <ResultsContainer />) : (<Shimmer /> )}
    </div>
  )
}

export default ResultsPage;
