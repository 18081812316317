import { configureStore } from "@reduxjs/toolkit";
import searchQuerySlice from "./searchQuerySlice";
import responseSlice from "./responseSlice";
import logInStatusSlice from "./logInStatusSlice";
import userSlice from "./userSlice";


const store = configureStore({
    reducer:{
       searchQuery : searchQuerySlice,
       response : responseSlice,
       logInStatus : logInStatusSlice,
       user : userSlice
    }

})

export default store;