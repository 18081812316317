import axios from 'axios';
import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate , Link } from "react-router-dom";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import "react-toastify/dist/ReactToastify.css";

const LogIn = () => {

  const origin = process.env.REACT_APP_BACKEND_ORIGIN;
  axios.defaults.withCredentials = true;

  const navigateTo = useNavigate();

  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs({...inputs, [name]: value});
  }

  const handleClick = async () => {
    try {
      const response = await axios.post(`${origin}/api/v1/users/login`, inputs);
      toast.success(`Welcome back ${response.data.data.user.name}`);
      setInputs({
        email: "",
        password: ""
      });
      navigateTo("/");
      console.log(response.data.data.user.role);
    } catch (err) {
      toast.error(err?.response?.data?.message);
      console.log(err);
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleClick();
    }
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  }

  return (
    <>
      <section className="text-gray-600 body-font">
        <div className="container px-5 py-24 mx-auto flex flex-wrap items-center">
          <div className="w-4/5 md:w-1/2 mx-auto justify-center bg-gray-100 rounded-lg p-8 flex flex-col md:ml-auto mt-10 md:mt-0">
            <h2 className="text-gray-900 text-lg font-medium title-font mb-5">
              Log In
            </h2>

            <div className="relative mb-4">
              <label htmlFor="email" className="leading-7 text-sm text-gray-600">
                Email
              </label>
              <input
                type="email"
                name="email"
                onChange={handleChange}
                value={inputs.email}
                className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8"
                onKeyDown={handleKeyDown}
              />
            </div>
            <div className="relative mb-4">
              <label htmlFor="password" className="leading-7 text-sm text-gray-600">
                Password
              </label>
              <div className="relative w-full">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  onChange={handleChange}
                  value={inputs.password}
                  className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8"
                  onKeyDown={handleKeyDown}
                />
                <button
                  type="button"
                  className="absolute text-2xl inset-y-0 right-0 px-3 flex items-center text-gray-600"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? <EyeInvisibleOutlined /> : <EyeTwoTone />}
                </button>
              </div>
            </div>
            <button
              onClick={handleClick}
              className="text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg"
            >
              Log In
            </button>
            <div className="mt-4 text-center">
              <Link to="/forgotPassword" className="text-indigo-500 hover:text-indigo-600 text-sm">
                Forgot Password?
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default LogIn;
