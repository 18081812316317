import React from 'react'

const ResultShimmerCard = () => {
  return (
    
    <div className='w-3/4 lg:w-1/4 sm:w-1/3  h-80 p-2 m-2 rounded-md bg-gray-300'>
        <div className='h-2/3 bg-slate-100'>

        </div>
        <div className='h-1/3 bg-slate-200 '>
           <div className='bg-slate-50 m-1'>

           </div>
        </div>
    </div>
    
  )
}

export default ResultShimmerCard