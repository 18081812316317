import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setQuery } from "../utils/searchQuerySlice";
import { Link } from "react-router-dom";
import axios from "axios";
import { setResponse } from "../utils/responseSlice";
import NavBar from "./NavBar";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import LogIn from "./LogIn";
import { setLoginStatus } from "../utils/logInStatusSlice";

import "react-toastify/dist/ReactToastify.css";
import { setRole, setName, setEmail } from "../utils/userSlice";

const Home = () => {
  const origin = process.env.REACT_APP_BACKEND_ORIGIN;
  axios.defaults.withCredentials = true;

  const navigateTo = useNavigate();
  const dispatch = useDispatch();

  const isLoggedIn = useSelector((store) => store.logInStatus.isLoggedIn);

  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    getLogInStatus();
  }, []);

  const getLogInStatus = async () => {
    try {
      const response = await axios.get(`${origin}/api/v1/users/isloggedin`);
      console.log(response);
      console.log(response.data.data.user.role);

      console.log(response.data.data.user.name);

      console.log(response.data.data.user.email);

      dispatch(setLoginStatus(true));
      dispatch(setRole(response.data.data.user.role));
      dispatch(setName(response.data.data.user.name));
      dispatch(setEmail(response.data.data.user.email));
    } catch (err) {
      console.log(err);
      dispatch(setLoginStatus(false));
      dispatch(setRole("user"));
    }
  };

  const handleClick = async () => {
    try {
      dispatch(
        setQuery({
          searchQuery,
        })
      );

      dispatch(setResponse({ ststus: false }));

      if (isLoggedIn) navigateTo("/search");


     // will be updated when correct approach comes in (only to be set from results page)
      localStorage.removeItem('searchQuery');
      localStorage.setItem('searchQuery', searchQuery);

      const response = await axios.get(
        `${origin}/api/v1/search?query=${searchQuery}`
      );
      console.log(response);

      dispatch(setResponse(response?.data));
    } catch (err) {
      console.log(err);
      toast.error(err.response?.data?.message);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleClick();
    }
  };

  return (
    <>
      <div className="flex justify-center items-center   sticky min-h-[75vh]">
        <div className="flex flex-col bg-gray-100 lg:w-1/2 w-5/6 rounded-lg shadow-lg -mt-20">
          <h1 className="h-20 text-6xl italic text-gray-700  m-auto">
            Cirnomy
          </h1>
          <div className="flex p-2 space-x-2">
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Shop Now"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyPress={handleKeyPress}
              className=" w-full  bg-white rounded border border-gray-500 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
            ></input>
            <div to={"/search"}>
              <div
                onClick={handleClick}
                className="cursor-pointer text-green-900 w-16 rounded-md m-auto p-2 bg-green-300 border-solid border-2 border-green-400 hover:bg-green-500 hover:text-white"
              >
                search
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
