    import { createSlice } from "@reduxjs/toolkit";

    const responseSlice = createSlice({
    name: "query response",
    initialState: {
        response: {},
    },
    reducers: {
        setResponse: (state, action) => {
        state.response = {};
        state.response = action.payload;
        console.log(state.response );
        },
    },
    });

    export const { setResponse } = responseSlice.actions;

    export default responseSlice.reducer;
