import { createSlice } from "@reduxjs/toolkit";

const searchQuerySlice = createSlice({
  name: "search query",
  initialState: {
    query: "",
  },
  reducers: {
    setQuery: (state, action) => {
      // console.log(`action ${action}`);

      state.query = "";
      state.query = action.payload;
      // console.log(`payload ${action.payload}`);
      // console.log(`query ${state.query}`);

    },
  },
});

export const { setQuery } = searchQuerySlice.actions;

export default searchQuerySlice.reducer;
